<template>
  <div :style="{ background: background,paddingLeft:'24px' }">
    <IntrouduceCpn :background="background" :isTextOnLeft="isTextOnLeft" :needRight="needRight">
      <template v-slot:text>
        <div class="slot-text-box" :style="{ alignItems: isTextOnLeft ? '' : 'flex-end' }">
          <div class="line"></div>
          <div class="text" :style="{ textAlign: isTextOnLeft ? '' : 'right' }">
            <slot name="text"></slot>
          </div>
          <div class="desc" :style="{ textAlign: isTextOnLeft ? '' : 'right' }">
            <slot name="desc"></slot>
          </div>
          <div class="textdown-img">
            <slot name="textdownImg"></slot>
          </div>
          <div class="introduce-btn" :style="{ alignItems: isTextOnLeft ? '' : 'flex-end' }">
            <slot name="introduceBtn"></slot>
          </div>
        </div>
      </template>
      <template v-slot:video>
        <slot name="video"></slot>
      </template>
    </IntrouduceCpn>
  </div>
</template>
<script>
import IntrouduceCpn from './components/IntrouduceCpn.vue'
export default {
  props: {
    background: {
      type: String,
      default: "#fff"
    },
    isTextOnLeft: {
      type: Boolean,
      default: true
    },
    needRight:{
      type:Boolean,
      default:true
    }
  },
  components: {
    IntrouduceCpn
  }
}
</script>

<style lang="scss" scoped>
.slot-text-box {
  display: flex;
  flex-direction: column;
  width: 450px;

  .line {
    width: 40px;
    height: 10px;
    background: #FFB700;
  }

  .text {
    font-size: 44px;
    font-weight: 600;
    color: #202020;
    line-height: 62px;
    margin: 20px 0;
  }

  .desc {
    font-size: 16px;
    color: #202020;
    line-height: 30px;
    opacity: .6;
  }

  .introduce-btn {
    display: flex;
    flex-direction: column;
  }
}
</style>