<template>
  <div :style="{ background: '#f7f7f7',paddingLeft:'24px' }">
    <IntroduceComponent :isTextOnLeft="false" background="#f7f7f7" height="844px">
      <template v-slot:title>
        丰富的闪卡模版、轻松应对不同知识
      </template>
      <template v-slot:desc>
        知识种类再多，惊叹闪卡模版都能帮你Hold住，不管是灵感、笔记、单词、题库，统统都行，更强大的是，惊叹还提供智能模版
      </template>
      <template v-slot:video>
        <div class="model-box">
          <div class="model-item" v-for="item in modelList" :key="item.id">
            <svg-icon :iconClass="item.icon" class="w-210 h-246 bdr-20"></svg-icon>
            <div class="name">{{ item.name }}</div>
          </div>
        </div>
        <div class="model-box">
          <div class="model-item" :style="{ opacity: item.id === 5 || item.id === 8 ? 0 : '' }" v-for="item in modelList2"
            :key="item.id">
            <svg-icon :iconClass="item.icon" class="w-210 h-246 bdr-20"></svg-icon>
            <div class="name">{{ item.name }}</div>
          </div>
        </div>
      </template>
    </IntroduceComponent>
  </div>

</template>

<script>
import IntroduceComponent from './components/IntroduceComponent.vue'
export default {
  components: {
    IntroduceComponent
  },
  data() {
    return {
      modelList: [
        {
          name: '笔记卡',
          icon: 'model_basic',
          id: 1
        },
        {
          name: '填空卡',
          icon: 'model_tiankong',
          id: 2
        }, {
          name: '单词卡',
          icon: 'model_word',
          id: 3
        }, {
          name: '选择题卡',
          icon: 'model_choice',
          id: 4
        },
      ],
      modelList2: [
        {
          name: '笔记卡',
          icon: 'model_basic',
          id: 5
        }, {
          name: '挖空卡',
          icon: 'model_wakong',
          id: 6
        }, {
          name: '智能模版',
          icon: 'model_zhineng',
          id: 7
        }, {
          name: '笔记卡',
          icon: 'model_basic',
          id: 8
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.model-box {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  .model-item {
    border-radius: 20px;

    .name {
      text-align: center;
      margin-top: 16px;
      font-size: 16px;
      color: #202020;
      line-height: 22px;
      opacity: .6;
    }
  }
}
</style>