<template>
  <div :style="{ background: background,paddingLeft:'24px' }">
    <IntroduceComponent>
      <template v-slot:title>
        智能模版、根据内容自动选模版
      </template>
      <template v-slot:desc>
        在记录灵感、知识点、单词时，我们总是需要不同的模板。模板的来回切换是一个多操作的环节，这样琐碎的操作，是对心流状态的一种破坏，我们希望使用惊叹的你，可以高度专注在记录和学习本身，而不会被繁琐的操作中断专注的状态。
为此我们原创设计了智能模板功能，你不再需要来回切换模板，智能模板会识别你的内容情况，自动匹配相应模板，以下是智能模板的识别规则，惊叹中会有常驻提示，你无需记忆哦。
      </template>
      <template v-slot:video>
        <slot name="video"></slot>
      </template>
    </IntroduceComponent>
  </div>
</template>
<script>
import IntroduceComponent from './components/IntroduceComponent.vue'
export default {
  props: {
    background: {
      type: String,
      default: "#fff"
    },
  },
  components: {
    IntroduceComponent
  }
}
</script>
