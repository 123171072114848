<template>
  <div class="introduce-cpn-box" :style="{ background: background, height: height }">
    <div class="introduce-top" :style="{ alignItems: isTextOnLeft ? '' : 'flex-end' }">
      <div class="line"></div>
      <div class="title" :style="{ textAlign: isTextOnLeft ? '' : 'right' }">
        <slot name="title"></slot>
      </div>
      <div class="desc" :style="{ textAlign: isTextOnLeft ? '' : 'right' }">
        <slot name="desc"></slot>
      </div>
    </div>
    <div class="video-box">
      <slot name="video"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    background: {
      type: String,
      default: "#fff"
    },
    isTextOnLeft: {
      type: Boolean,
      default: true
    },
    height: {
      type: String,
      default: "720px"
    },
  }
}
</script>
<style lang="scss" scoped>
.introduce-cpn-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 1200px;
  margin: 0 auto;

  .introduce-top {
    display: flex;
    flex-direction: column;
    // padding: 0 156px;

    .line {
      width: 40px;
      height: 10px;
      background: #FFB700;
    }

    .title {
      font-size: 44px;
      font-weight: 600;
      color: #202020;
      line-height: 62px;
      margin-top: 16px;
    }

    .desc {
      font-size: 16px;
      color: #202020;
      line-height: 30px;
      opacity: .6;
      margin-top: 20px;
    }
  }
}
</style>