<template>
  <div style="background: #fff;padding-left: 24px;">
    <IntroduceComponent background="#fff" height="760px">
      <template v-slot:video>
        <img src="../../assets/images/index/web_image_f@2x.png" alt="" class="h-500 w-fill position-relative" style="left: 26px;">
      </template>
      <template v-slot:title>
        卡牌双链、建立你的知识网络
      </template>
      <template v-slot:desc>
        卡片双链是点对点链接，是具体知识点链接到具体知识点，它有效避免了长篇笔记中有大量无关信息干扰的问题，使得链接的过程更加精准顺畅。 
建立个人知识网络的好处在于，我们是把知识点成组，成网。与此同时，知识存储单位也实现了从多到一的跨变，点牵起线，线带动面，记忆与提取信息也因此变得更加灵活和轻松。<br>
在编辑器输入@，就可以与其他卡片产生链接，在学习和浏览卡片的时候，还可以通过点击@处查看@和被@的卡片
      </template>
    </IntroduceComponent>
  </div>
</template>

<script>
import IntroduceComponent from './components/IntroduceComponent.vue'
export default {
  components: {
    IntroduceComponent
  }
}
</script>