<template>
  <div class="index-container-introduce" :style="{ background: background }">
    <template v-if="isTextOnLeft">
      <div class="text-container">
        <slot name="text"></slot>
      </div>
      <div class="img-container position-relative" style="right:-26px" :style="{right:needRight?'-26px':0}">
        <slot name="video"></slot>
      </div>
    </template>
    <template v-else>
      <div class="img-container position-relative" style="left:-26px">
        <slot name="video"></slot>
      </div>
      <div class="text-container">
        <slot name="text"></slot>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    background: {
      type: String,
      default: "#fff"
    },
    isTextOnLeft: {
      type: Boolean,
      default: true
    },
    needRight:{
      type:Boolean,
      default:true
    }
  }
}
</script>

<style lang="scss" scoped>
.index-container-introduce {
  display: flex;
  justify-content: space-between;
  height: 720px;
  width: 1200px;
  margin: 0 auto;

  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .img-container {
    display: flex;
    align-items: center;
  }
}
</style>