<template>
  <div style="background: #fff">
    <div class="down-load-container-box">
      <div class="down-load-container">
        <div class="down-load-block"></div>
        <div class="down-load-title">下载闪记卡，用先进工具，领先更多人</div>
        <div class="down-load-desc">同时支持：Windows | MacOS | iOS | Android</div>
        <div class="down-load-box">
          <div class="down-load-item" @click="downLoadClick(index)" v-for="(item, index) in downLoadList" :key="index">
            <div class="down-load-item-imgbox">
              <svg-icon :iconClass="item.icon" class="w-60 h-60"></svg-icon>
            </div>
            <div class="item-text">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="bg-f">
        <div class="text-align-center c-9 pd-t-44 pd-b-20 fs-14">
          <div>闪记卡 | 惊叹笔记 | <span class="cursor-pointer" @click="showAbout = true">关于我们</span></div>
          <div class="mg-t-5">深圳市惊叹科技有限公司 <a class="c-9" style="text-decoration: none" target="_blank"
              href="https://beian.miit.gov.cn/">{{ beian }}</a>
            | <img src="https://amazing-resources.oss-cn-shenzhen.aliyuncs.com/default/16711692081860_.pic.png" style="width: 16px;" />粤公网安备 44030302002418号
          </div>
          <div class="text-align-center pd-5">地址：深圳市宝安区新安街道海滨社区N23区海天路15-1号卓越宝中时代广场一期A栋1109</div>
          <div class="text-align-center pd-5">联系方式：amazingcompany@163.com | 电话：17388758327</div>
        </div>
      </div>
      <div class="display-flex justify-content-center mg-t-5 fs-14">
        <div class="mg-r-20 c-9 ">版本：2.0.0</div>
        <div @click="checkPrivacy" class="cursor-pointer c-9">隐私：点击查看</div>
      </div>
      <div class="mg-t-5 text-align-center c-9 pd-b-20 fs-14">
        <div class="text-align-center position-relative" style="left:-5px">权限：允许应用拥有网络访问权限｜允许应用读取SD卡的内容</div>
      </div>
      <div class="mg-t-5 text-align-center c-9 pd-b-20 fs-14" @click="toTalkAI">
        <div class="text-align-center position-relative cursor-pointer" style="left:-5px">友情连接：TalkAI练口语</div>
      </div>
      <el-dialog :visible.sync="showAbout">
        <div slot="title">关于我们</div>
        <div>
          深圳市惊叹科技有限公司旗下惊叹·大纲闪卡是一款先进的学习效率工具，利用大纲+卡片笔记，你可以实现既颗粒化又结构化的笔记方式，相比传统的线性笔记，大纲闪卡笔记颗粒度更小更灵活、可视化效果更好，更适合在学习做笔记场景下使用、帮你轻松建立强大的个人知识库
          <br>
          <br>
          惊叹的愿景是为了提升人类传播和吸收知识的效率，我们希望通过技术和产品的力量加快每个人吸收知识的效率、提高知识流动和传播速度、让人们能更方便、更乐于交流观点
          <br>
          <br>
          公司地址：深圳市宝安区新安街道海滨社区N23区海天路15-1号卓越宝中时代广场一期A栋1109
          <br>
          邮箱：AmazingCompany@163.com
          <br>
          联系电话：17388758327
        </div>
      </el-dialog>
    </div>
  </div>

</template>
<script>
export default {
  data() {
    return {
      showAbout: false,
      beian: '粤ICP备2022002259号',
      downLoadList: [
        { name: "闪记卡 Windows 客户端", icon: 'windows_big' },
        { name: "闪记卡 MacOS 客户端", icon: 'macos_big' },
        { name: "闪记卡 iOS 客户端", icon: 'ios_big' },
        { name: "闪记卡 Android 客户端", icon: 'android_big' },
      ]
    }
  },
  methods: {
    downLoadClick(index) {
      if (index === 0) {
        window.open('https://mniiz2uj96.feishu.cn/file/boxcnh1VCPjd1t79FOXf0VBejHf')
      } else if (index === 1) {
        window.open('https://mniiz2uj96.feishu.cn/file/boxcnEDEII5uQbxByKHy2Ig3IOd')
      }else if(index === 2){
        this.$emit('onDownloadClick',0)
        // window.open("https://apps.apple.com/cn/app/id6446388620")

      } else {
        this.$emit('onDownloadClick',1)
      }
    },
    checkPrivacy() {
      location.href = 'https://www.amazingmemo.com/privacy.html'
    },
    toTalkAI(){
      location.href = "https://chat-speak.amazenote.com/"
    }
  }
}
</script>
<style lang="scss" scoped>
.down-load-container-box {
  background-color: #fff;
  width: 1200px;
  margin: 0 auto;

  .down-load-container {
    padding: 140px 156px;
    padding-bottom: 0;
    background-color: #fff;

    .down-load-block {
      margin: 0 auto;
      width: 40px;
      height: 10px;
      background: #FFB700;
    }

    .down-load-title {
      text-align: center;
      font-size: 44px;
      font-weight: 600;
      color: #202020;
      line-height: 62px;
      margin-top: 20px;
    }

    .down-load-desc {
      text-align: center;
      margin-top: 12px;
      margin-bottom: 48px;
      font-size: 18px;
      color: rgba(32, 32, 32, .6);
      line-height: 38px;
    }

    .down-load-box {
      display: flex;
      justify-content: space-between;
      margin-bottom: 94px;

      .down-load-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .down-load-item-imgbox {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 144px;
          height: 144px;
          background-color: #fff;
          border-radius: 22px;
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
          border: 1px solid transparent;

          &:hover {
            border-color: #FFB700;
          }
        }

        &:hover {
          cursor: pointer;
        }

        .item-text {
          font-size: 14px;
          font-weight: 500;
          color: rgba(32, 32, 32, .6);
          line-height: 16px;
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
